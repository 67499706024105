.recorder {
    margin-top: 1.2rem;
}

.playBtn {
  box-shadow: 0 0 0 0 !important;
}

.playBtnImg {
  width: 7rem;
  height: 7rem;
  @media (max-width: 575px) {
    width: 6rem;
    height: 6rem;
}
}

.pauseBtnImg {
  width: 4rem;
  height: 4rem;
}


.startOver {
  text-align: center;
  font-weight: bold;
  font-size: 1.3rem;
  max-width: 30px;
  left: -80px;
  line-height: 20px;
}

.timer {
  color: var(--color-primary);
  font-size: 1.9rem;
  font-weight: bold;
  @media (max-width: 575px) {
    font-size: 1.3rem;
  }
}

#double-wave {
  stroke: var(--color-primary);
  stroke-width: 0.1rem;
  @media (max-width: 575px) {
    stroke-width: 0.3rem;
  }
  @media (min-width: 576px) {
    @media (max-width: 1200px) {
      stroke-width: 0.2rem;
    }
  }
}
#audioBars {
  fill: var(--color-light-grey-2);
}
.waveAmination {
  width: 50% !important;
}

.verticalLine {
  width: 4px;
  background-color: var(--color-black);
  border-left: 1px solid var(--color-black);
  height: 100%;
  border-radius: 100px;
}