.login-screen{
  .forgot-password{
    color: var(--color-black);
    text-decoration: underline;
  }

  .signup-text-container{
    a{
      text-decoration: underline;
    }
  }

  .submit-button-full{
    margin-top: 1em;
    padding-top: 1.2em;
    padding-bottom: 1.2em;
    width: 100%;
    font-size: 1.1em;
  }
}
