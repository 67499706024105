.footerNav {

  @media screen and (max-width: 767px) {
    padding: 0.7em 0;

    .nav-link{
      padding: 4px 2px;
    }

    .copyrights-container{
      padding: 0 20px;

      .nav-link {
        float: right;
      }
    }
    .nav-links-container{
      padding: 0 0px 10px 0px;
    }
  }

  .nav-link{
    color: black !important;
    font-family: var(--font-primary-bold), sans-serif;
    padding: 0.5rem 0.3rem;
  }

  .nav-link-logo {
      display: block;
      margin-right: 3px;
  }


}