.subTitle {
    max-width: 260px;
}

.postRiff {
    // bottom: 100px;
    height: 50px;
    // position: fixed;
    background-color: var(--color-secondary-medium-opacity) !important;
    border: none !important;
    box-shadow: 0 0 0 0 !important;
    font-size: 1.4rem;
    border-radius: 0.9rem;
    // z-index: 10;
    &-active {
        background-color: var(--color-secondary) !important;
        border-color: var(--color-secondary) !important;
    }

    // @media (max-width: 575px) {
    //     width: 83.33% !important; 
    //     left: 8.33% !important;
    // }
    // @media (max-width: 991px) {
    //     width: 50%; 
    //     left: 25%;
    // }
    // @media (min-width: 992px) {
    //     width: 33.33%; 
    //     left: 33.33%;
    // }
}

.sideRiff {
    position: fixed;
    top: 120px;
    width: 25%;
    left: 70%;
    z-index: 5;

    @media (max-width: 575px) {
        width: 100%; 
        left: 0;
        top: 65px;
    }

}

.topRowFixed {
    background-color: white;
    z-index: 1;
    top: 65px;
}