.debates {
  height: 100vh;
  padding-left: 10px;
  padding-right: 10px;

  @media screen and (min-width: 1400px) {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
  }

  .debates-header {
    padding: 10px;
    align-items: center;
  }

  .debates-header-item {
    padding-left: 7px;
  }

  .downloadOnAppStore {
    height: 30pt;

    @media screen and (max-width: 768px) {
      height: 15pt;
    }
  }

  .getItOnGooglePlay {
    height: 30pt;

    @media screen and (max-width: 768px) {
      height: 15pt;
    }
  }

  .download-app {
    text-align: left;
    padding-left: 10px;
    font-size: 15pt;

    @media screen and (max-width: 768px) {
      font-size: 8pt;
    }
  }

  .top-content {
    text-align: center;
  }

  .contestant-image {
    border-radius: 50%;

    @media screen and (max-width: 768px) {
      height: 70px;
    }
  }

  .contestant-percentage {
    margin-right: 10px;
  }

  .logo {
    width: 30px;
    height: 30px;
  }

  .learn-more-title {
    padding-left: 20px;
    color: black;
    font-size: 1.1em;
  }

  .hashtag-debates {
    font-size: 1.1em;
  }

  .how-it-works {
    text-decoration: underline;
  }

  .usFlag {
    width: 115px;
    height: 70px;
  }

  .title {
    font-size: 34pt;

    @media screen and (max-width: 768px) {
      font-size: 20pt;
    }
  }

  .sub-title {
    font-size: 21pt;

    @media screen and (max-width: 768px) {
      font-size: 16pt;
    }
  }

  .vote-for {
    padding: 20px 10px;
    border-radius: 10px;
  }

  .vote-for-trump {
    color: rgb(244, 0, 0);
    border: 2px solid rgb(244, 0, 0);
  }

  .vote-for-biden {
    color: rgb(0, 50, 244);
    border: 2px solid rgb(0, 50, 244);
  }

  .biden-content{
    padding-right: 100px;

    @media screen and (max-width: 768px) {
      padding-right: 10px;
    }
  }

  .trump-content{
    padding-left: 100px;

    @media screen and (max-width: 768px) {
      padding-left: 10px;
    }
  }

  .record-container {
    padding: 10px;
    border-radius: 10px;
    border: 2px solid var(--color-primary);
    color: var(--color-primary);
  }

  .form-container{
    padding: 10px;
    border-radius: 10px;
    border: 2px solid var(--color-primary);
    color: var(--color-primary);
  }
}

.submit-button {
  border-radius: 50px;
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
}

.switchTeamTxt {
  text-decoration: underline;
}