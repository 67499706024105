.searchTagIcon {
    border-width: 0 !important;
    width: 42px;
    height: 42px;
}

.searchInput {
    background-color: #e9ecef !important;
    border-left-width: 0;
    color: #000 !important;
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    border-width: 0 !important;
}

.searchInputShow {
    background-color: #e9ecef !important;
    border-left-width: 0;
    color: #000 !important;
    border-top-right-radius: 20px !important;
}

.inputList {
    left: 0;
    top: 38px;
    width: 100%;
    background-color: #fff;
    color: #000 !important;
    z-index: 5;
    overflow: auto;
    max-height: 30vh;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border: solid 1px #e9ecef;
    &::-webkit-scrollbar {
        width: 0px;
      }
}

.imgContainerShow {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 20px !important;
}

.listRow {
    border-bottom: solid 1px #e9ecef;
    font-size: 1rem;
    font-weight: bold;
    padding: 1rem;
    &:hover {
        background-color: #e9ecef;
    }
}

.listRowSelect {
    color: var(--color-primary);
}

.hashTag {
    border: solid 1px #e9ecef !important;
    border-radius: 10px;
    padding: 10px;
    font-size: 1rem;
    font-weight: bold;
    max-width: max-content !important;
}

.container {
    border-width: 0 !important;
}

.searchInputTag {
    padding-top: 9px;
}