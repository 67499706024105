.sideRiffContainer {
    border: solid 3px var(--color-primary);
    border-radius: 16px;
    padding: 25px;
    &-md {
        border-width: 0;
        border-radius: 0;
        border-bottom-width:3px;
        background-color: var(--color-white);
        padding: 10px;
        @media (max-width: 767px) {
            padding: 0 10px;
        }
    }
}

.sideRiffContainerhide {
    height: 0;
    opacity: 0;
    padding: 0;
    margin: 0;
    border: 0;
}
.playFilled {
    width: 26px;
    height: 27px;
    &-md {
        width: 36px;
        height: 36px;
        @media (max-width: 767px) {
            width: 26px;
            height: 26px;
        }
    }
}

.pauseFilled {
    width: 26px;
    height: 27px;
    &-md {
        width: 36px;
        height: 36px;
        @media (max-width: 767px) {
            width: 26px;
            height: 26px;
        }
    }
}

.audio-container {
    align-items: center;
}

.waveFormActive, .waveForm{
    width: 100%;
}

.waveFormActiveTransition {
    clip-path: inset(0% 0% 0% 0%) !important;
}

.audioBar {
    width: 4px;
    border-radius: 4px;
    background-color: var(--color-primary);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
}
.audioBar.horizTranslate {
    margin-left: 100% !important;
}

.audio-container-bar {
    position: relative;
    margin: auto;
    &-md {
        width: 300px;
        @media (max-width: 767px) {
            width: 150px;
        }
    }
    &-column {
        max-width: max-content;
    }
}

.yourRiff {
    font-family: var(--font-primary-light);
}

.riffButton {
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
    width: 26px;
    // height: 27px;
}

.playPauseBtnMd {
    max-width: max-content;
    margin-right: 25px;
    @media (max-width: 767px) {
        margin-right: 10px;
    }
}

.closeIconSideRiff {
    width: 20px;
    @media (max-width: 767px) {
        width: 11px;
    }
}

.sideRiffName {
    font-size: 12px;
}

.audioBarContainerSide {
    height: 180%;
    width: 150%;
    top: -50%;
    left: -25%;
    cursor: grabbing;
}