
.micBtn {
    background-color: var(--color-primary) !important;
    border: 0;
    border-radius: 15px;
    box-shadow: none !important;
    min-height: 92px;
}

.recordingContainer {
    border: solid 3px var(--color-primary);
    border-radius: 15px;
    min-height: 92px;
}

/* Events */
.omPride2021 {
    background: url(../../assets/images/bg-rainbow.png);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;

    .pageTag {
        color: #fff;
    }

}

#openMicBg {
    background: #ff0;
    height: 200px;
    width: 100%;
    position: absolute;
    top: 45%;
    left: 0px;
}

.openMicHolder {
    border: 1px solid #b4b4b4;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
    padding-top: 50px;
    background: #fff;
    border-radius: 5px;

}

.openMic {
    .verticalLine {
        width: 4px;
        background-color: var(--color-black);
        border-left: 1px solid var(--color-black);
        height: 40px;
        border-radius: 15px;
    }

    #double-wave {
        stroke: var(--color-primary);
        stroke-width: 1rem;
    //     @media (max-width: 575px) {
    //       stroke-width: 0.3rem;
    //   }
    }
    #audioBars {
        fill: var(--color-light-grey-2);
    }
    .waveAmination {
        width: 50% !important;
    }
    .micTimer {
        color: var(--color-black);
        font-family: var(--font-primary-light);
        font-size: 20px;
    }
  .stopBtn {
        background-color: transparent !important;
        width: 50px;
        // height: 50px;
        border: none;
        box-shadow: none !important;
  }
  .countDownTimer{
    color: var(--color-primary);
    font-size: 30px;
  }
  .startOverBtn {
      color: var(--color-primary);
      border: solid 2px var(--color-primary);
      background-color: transparent !important;
  }
  .formBtn {
    border-radius: 20px;
    min-height: 40px;
    box-shadow: none !important;
    font-size: 20px;
  }
  .shareBtn {
      border: solid 2px var(--color-secondary) !important;
      color: var(--color-white);
      background-color: var(--color-secondary) !important;
  }
  .how-it-works {
    border-bottom: 1px solid;
  }
  .exploreTxt {
      color: var(--color-black);
  }
}