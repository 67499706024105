.jumpToTxt {
    font-weight: bold;
    font-size: 1.2rem;
}

.jumpToTxtSelected {
    color: var(--color-primary);
}

.jumpToIcons {
    width: 1.5rem;
    height: 1.5rem;
}

@media (max-width: 575px) {
    .jumpToIcons {
        width: 1rem;
        height: 1rem;
    }
    .jumpToTxt {
        font-weight: bold;
        font-size: 0.9rem;
    }
}