.settingsContainer {
    border: solid 1px var(--color-light-grey);
    border-radius: 10px;
    margin-top: 30px;

    &-row {
        padding: 1.3em 1.9em 1.3em 1.5em;
        @media (max-width: 991px) {
            padding: 1em;
        }
        border-bottom: solid 1px var(--color-light-grey);
        &-first {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
        &-last {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            border-bottom: none;
        }
        &-red {
            color: var(--color-error);
        }
        &:hover {
            background-color: var(--color-light-grey);
        }
        &-connectTxt {
            color: var(--color-primary);
        }
    }
}

.settings-social-networks-txt {
    padding: 0 1.9em 0 1.5em;
        @media (max-width: 991px) {
            padding: 0 1em;
        }
}