.playFilled {
    width: 26px;
    height: 27px;
}

.pauseFilled {
    width: 26px;
    height: 27px;
}

.audio-container {
    align-items: center;
}

.waveFormActive, .waveForm{
    width: 100%;
}

.waveFormActiveTransition {
    clip-path: inset(0% 0% 0% 0%) !important;
}

.audioBar {
    width: 4px;
    border-radius: 4px;
    background-color: var(--color-primary);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
}
.audioBar.horizTranslate {
    margin-left: 100% !important;
}

.audio-container-bar {
    position: relative;
    margin: auto;
}

.yourRiff {
    font-family: var(--font-primary-light);
}

.riffButton {
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
    width: 26px;
    // height: 27px;
}

.audioBarContainer {
    height: 300%;
    width: 200%;
    top: -100%;
    left: -50%;
    cursor: grabbing;
}

.greyImg {
    filter: grayscale(100%);
}