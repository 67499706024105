.resend-code{
  cursor: pointer;
  text-decoration: underline;
  font-family: var(--font-primary-light), sans-serif;
}

.otp-container {
  max-width: 100px;
}

.sub-header-title {
  margin-bottom: 1em;
}

.progress-bar-wrapper{
  margin-bottom: 3em;
}

.form-control#form-handle::placeholder {
  color: var(--color-secondary-medium-opacity);
  opacity: 1;
}