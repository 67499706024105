.sharedRiff {
    height: 100vh;
    padding-left: 10px;
    padding-right: 10px;

    .sharedRiffHeader {
        padding: 10px;
        align-items: center;
    }

    .downloadApp {
        text-align: left;
        padding-left: 10px;
        font-size: 15pt;
    
        @media screen and (max-width: 768px) {
          font-size: 8pt;
        }
    }

    .learnMoreTitle {
        padding-left: 25px;
        color: black;
        font-size: 1.3em;
      }

    .logo {
        width: 3em;
        height: 3em;
    }
    .logoHeader {
        width: 4.5em;
        height: 4.5em;
    }
    .downloadOnAppStore {
        height: 45pt;
      
        @media screen and (max-width: 1400px) {
          height: 35pt;
        }
      
        @media screen and (max-width: 767px) {
          float: right !important;
        }
    }
      
    .getItOnGooglePlay {
        height: 45pt;
    
        @media screen and (max-width: 1400px) {
            height: 35pt;
        }
        
        @media screen and (max-width: 767px) {
            float: left;
        }
    }
    .descriptionTxt {
        line-height: 150%;
        font-weight: 100 !important;
    }
    .riff {
        height: auto;
    }
}