.no-padding {
  padding: 0;
}

.nav {
  background-color: var(--color-primary);
  padding: 1.875em;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;

  @media (max-width: 1400px) {
    padding: 0.8em;
  }

  .nav-item {
    align-content: center;
    font-size: 1.25em;
  }

  @media (max-width: 1400px) {
    .nav-item {
      font-size: 1em;
    }
  }
}

.nav.scrolled {
  border-bottom: 0.1875em solid var(--color-white-opacity);
}

.nav-link:not(.nav-link-logo) {
  color: var(--color-white);
  font-family: var(--font-primary-bold), sans-serif;
}

.nav-link-logo {
  padding-top: 0;
  padding-bottom: 0;
}

.app-headline {
  font-family: var(--font-primary-bold), sans-serif;
  font-size: 5em;
}

@media (max-width: 1400px) {
  .app-headline {
    font-size: 3em;
  }
}

.app-sub-header {
  font-family: var(--font-primary-bold), sans-serif;
  font-size: 2.5em;
}

@media (max-width: 1400px) {
  .app-sub-header {
    font-size: 2em;
  }
}

@media (max-width: 961px) {
  .app-sub-header {
    font-size: 1.5em;
  }
}

.app-sub-header-2 {
  font-family: var(--font-primary-bold), sans-serif;
  font-size: 2em;

  @media (max-width: 1400px) {
    font-size: 1.5em;
  }

  @media (max-width: 961px) {
    font-size: 1.3em;
  }
}

.app-inner-body {
  font-size: 0.9em;
}

.app-body-large {
  font-size: 1.5em;
}

.app-body, .app-value {
  font-size: 1.1em;
}

.app-section-header {
  font-size: 1.5em;
  font-family: var(--font-primary-bold), sans-serif;
}

@media (max-width: 1400px) {
  .app-body, .app-value {
    font-size: 1em;
  }

  .app-section-title {
    font-size: 1.2em;
  }
}

@media (max-width: 767px) {
  .app-body, .app-value {
    font-size: 0.95em;
  }

  .app-section-header {
    font-size: 1.3em;
  }

  .app-inner-body {
    font-size: 0.7em;
  }
}

.app-value, .app-section-title {
  font-family: var(--font-primary-bold), sans-serif;
}

.app-section-title.light {
  font-family: var(--font-primary-light), sans-serif;
}

.app-body {
  font-family: var(--font-primary), sans-serif;
}

// Typography

.app-section-header {
  font-size: 24pt;
}

.app-section-sub-header {
  font-size: 14pt;
  letter-spacing: -0.18pt;
}

@media (max-width: 767px) {
  .app-section-header {
    font-size: 18pt;
  }

  .app-section-sub-header {
    font-size: 12pt;
  }
}

// Font styles

.light {
  font-family: var(--font-primary-light), sans-serif;
}

.bold {
  font-family: var(--font-primary-bold), sans-serif;
}

// Page style

.page-content {
  position: relative;
  top: 100px;

  @media (max-width: 1400px) {
    top: 4em;
  }
}

// Buttons

.btn-outline-primary, .btn-outline-primary:active{
  font-family: var(--font-primary-bold), sans-serif;
  border-width: 2px;
}

.btn-outline-primary, .btn-outline-primary:active .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: var(--color-secondary) !important;
  background-color: #fff !important;
  border-color: var(--color-secondary) !important;
}

// Links

a {
  color: var(--color-primary);
  font-family: var(--font-primary-bold), sans-serif;
}

a:hover {
  color: var(--color-secondary);
}

// Text colors

.primary-text {
  color: var(--color-primary);
}

.secondary-text {
  color: var(--color-secondary);
}

.error-text {
  color: var(--color-error);
}

.black-text {
  color: var(--color-black);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.form-control, .input-group-text {
  border-radius: 30px;
  border-color: var(--color-light-grey);
  font-family: var(--font-primary-bold), sans-serif;
  font-size: 14pt;
  color: var(--color-black-2);
}

.form-control:focus, .form-control.active {
 border-color: var(--color-light-grey) !important;
  box-shadow: none;
}

.error-message {
  color: var(--color-error);
  font-size: 14pt;
}

// Authentication screen

.authentication-screen {
  .back-container {
    color: var(--color-black);
    text-decoration: none;
    line-height: 40px;

    .chevronLeft {
      margin-top: -4px;
    }

    .app-section-title {
      padding-left: 1em;
    }
  }

  .login {
    font-size: 16pt;
  }

  .cancel, .login {
    margin-left: 0.4em;
    text-decoration: underline;
  }

  .page-content {
    padding-top: 4em;
    @media screen and (max-width: 767px) {
      padding-top: 2em;
    }
  }

  .submit-button {
    padding: 0.7em 3em;
    border-radius: 0.4em;
  }

  .submit-container {
    padding-top: 1.5em;
  }

  .spinner {
    margin-left: 0.4em;
    width: 1em;
    height: 1em;
  }

  .authentication-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}

.menu-item-text {
  margin-left: 0.4em;
}

.clickable {
  cursor: pointer;
}

.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-white-opacity);
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
}


.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}