.copyInviteLinkBtn {
    color: var(--color-secondary) !important;
    border-color: var(--color-secondary) !important;
    border-radius: 10px;
    padding: 0.5em 1.5em;
    font-weight: bold;
    font-size: 20px;
    margin-top: 50px;

    @media (max-width: 991px) {
        padding: 0.5em 1em;
        margin-top: 30px;
    }
}

.inviteFriendsDef {
    font-size: 17px;
}
