.resultTitle {
    font-weight: bold;
    font-size: 2rem;
}

.resultContainer {
    border: solid 1px var(--color-light-grey);
    border-radius: 10px;
}

.resultRow {
    border-bottom: solid 1px var(--color-light-grey);
    font-weight: bold;
    &:hover {
        background-color: var(--color-light-grey);
    }
}

.greyText {
    color: var(--color-light-grey-2);
}

.highlighted {
    color: var(--color-primary);
}

.searchIconImg {
    width: 1.3rem;
    height: 1.3rem;
}

@media (max-width: 575px) {
    .searchIconImg {
        width: 1rem;
        height: 1rem;
    }
    .resultTitle {
        font-weight: bold;
        font-size: 1.5rem;
    }
}

.clearAll {
    color: var(--color-primary);
    font-weight: bold;
}