.riffContainer{
  border: 2px solid var(--color-light-grey);
  border-radius: 0.75em;
}
.riff{
  padding: 1.5em 1.5em 0.5em 1.5em;

  &-side {
    border-color: var(--color-primary);
    @media (max-width: 575px) {
        background-color: var(--color-white);
        border-width: 0;
        border-bottom-width: 3px;
        border-radius: 0;
    }
  }

  @media screen and (max-width: 1400px) {
    padding: 0.8em 0.8em 0.5em 0.8em;
  }
}
  .play {
    width: 14px;
    height: 16px;
  }

  .comment {
    width: 17px;
    height: 16px;
  }

  .echo {
    width: 18px !important;
    height: 14px !important;
  }

  .like {
    width: 18px;
    height: 18px;
  }

  .like-inactive {
    width: 18px;
    height: 18px;
  }

  .options {
    width: 20px;
    height: 6px;
  }

  .shareOutline {
    width: 17px;
    height: 17px;
  }

  .count {
    padding-left: 5px;
    font-size: 14px;
    color: rgb(9, 8, 9);
  }

  .riff-footer {
    margin-top: 0.5em;
    align-items: center;

    .riff-footer-element{
      margin-right: 1.5em;
    }
  }

  .playFilled{
    width: 26px;
    height: 27px;
  }

  .riffButton {
    cursor: pointer;
  }

  .pauseFilled{
    width: 26px;
    height: 27px;
  }

  .audio-container{
    align-items: center;
  }

  .waveFormActive, .waveForm{
    width: 100%;
  }

  .waveFormActiveTransition {
    clip-path: inset(0% 0% 0% 0%) !important;
  }

  .riffName{
    margin-top: 0.5em;
  }

  .audioBar {
    width: 4px;
    border-radius: 4px;
    background-color: var(--color-primary);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }
  .audioBar.horizTranslate {
    margin-left: 100% !important;
  }

  .audio-container-bar {
    position: relative;
    margin: auto;
  }


.riffContainer.hide{
  height: 0;
  opacity: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.riffContainer{
  transition: opacity 0.2s ease-in-out, height 0.2s, padding 0.5s, border 0.1s;
  // height: 100%;
  overflow: hidden
}

.timeContainer {
  min-width: max-content;
}

.commentInput {
  border: solid 1px var(--color-light-grey);
  border-radius: 20px;

  &:focus {
    outline: none;
  }
}

.commentInputContainer {
  border-top: solid 4px var(--color-light-grey);
}

.commentInputSend {
  position: absolute;
  right: 6px;
  top: -1px;
  width: 25px;
  height: 25px;
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
}

.commentInputSendTxt {
  position: absolute;
  right: 5px;
  top: 4px;
  width: 50px;
  height: 25px;
  border-radius: 12.5px;
  background-color: var(--color-secondary) !important;
  border-color: var(--color-secondary) !important;
}

.commentInputSendAudio {
  width: 64px;
  height: 39px;
  border-radius: 20px;
  background-color: var(--color-secondary) !important;
  border-color: var(--color-secondary) !important;
}

.commentRow {
  transition: opacity 0.2s ease-in-out, height 0.2s, padding 0.5s, border 0.1s;
  border-bottom: solid 2px var(--color-light-grey);
}

.commentRow.hide{
  height: 0 !important;
  opacity: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
}

.echoCommentContainer {
  color: var(--color-light-grey-3);
  max-width: 90% !important;
}

.echoedTxt {
  min-width: 35% !important;
  max-width: 30% !important;
}

.blueText {
  color: var(--color-secondary);
}

.goToPodcastBtn {
  border-radius: 10px;
  font-size: 15px;
}