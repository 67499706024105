.titles-separator {
  background-color: var(--color-light-grey);
  padding: 0 2px;
  margin: 2px 2em 5px 2em;
  min-height: 100%;
  border-radius: 4px;

  @media (max-width: 1400px) {
    margin: 0 1em 5px 1em;
  }
}

.connection-container {
  padding: 0 1.5em 0.5em 1.5em;
}

.connections-scroll-container{
  margin-bottom: 1.5em;
  border: 3px solid var(--color-light-grey);
  border-radius: 30px;
  padding-top: 0.5em;
}

.connection-container-item {
  margin-top: 1em;
  border-bottom: 3px solid var(--color-light-grey);
}

.connection-container-last-item{
  margin-top: 1em;
}