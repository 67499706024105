
.record{
    &__header {
        &-title {
            font-size: 1.5rem;
            font-weight: bold;
            text-align: center;
        }
        
        &-subtitle {
            text-align: center;
            font-size: 1em;
        }
    }
    &-finishBtn {
        background-color: var(--color-secondary) !important;
        border-color: var(--color-secondary) !important;
        font-size: 1.4rem;
        padding: 0.9rem;
        font-weight: bold;
        border-radius: 0.9rem;
    }
    &__footer {
        text-align: center;
        max-width: 320px;
        &-link {
            text-decoration: underline;
            cursor: pointer;
            color: var(--color-secondary);
            font-family: var(--font-primary), sans-serif;
        }
    }
}