.welcome-toast{
  background-color: var(--color-secondary) !important;
  color: var(--color-white) !important;
  font-family: var(--font-primary-bold), sans-serif !important;
  border-radius: 10px !important;

  .Toastify__close-button{
    display: none;
  }
}

.errorTxt {
  color: var(--color-error);
}

.playAllBtnContainer {
  min-width: max-content;
}

.topFixedRiff {
  z-index: 10;
  top: 66px;
  @media (max-width: 767px) {
      top: 65px
  }
  &-hide {
    z-index: -1;
  }
}

.blueTxt {
  color: var(--color-secondary);
}