.profile-content {
  margin-top: 3em;
}

.followers-number {
  margin-left: 3em;
}

.bio-text{
  margin-bottom: 1.5em;
}

.clickable-items{
  border-bottom: 2px solid var(--color-light-grey-2);
}

a.connection.default-link:hover {
  text-decoration: none;

  .primary-text{
    color: var(--color-primary) !important;
  }
}

a.connection.disabled-link:hover, a.disabled-link:-webkit-any-link {
  // color: var(--color-primary) !important;
  text-decoration: none;
  cursor: default !important;
}

.followBtn {
  padding: 6px 25px;
  border-radius: 10px;
}
.followingBtn {
  padding: 6px 25px;
  border-radius: 10px;
  background-color: var(--color-secondary) !important;
  border-color: var(--color-secondary) !important;
}

.statusTxt {
  &-selected {
    color: var(--color-primary);
  }
}

.statusSeparator {
  width: 0;
  height: 20px;
  border: solid 2px var(--color-light-grey);
  border-radius: 2px;
  margin: 0 19px;
  &-xs {
    margin: 0 10px;
    border-width: 1px;
  }
}

.statusesContainer {
  margin-top: 30px;
}