.blockedMutedSeparator {
    width: 0;
    height: 20px;
    border: solid 2px var(--color-light-grey);
    border-radius: 2px;
    margin: 0 19px;
    &-xs {
      margin: 0 10px;
      border-width: 1px;
    }
}

.blockedMutedSelected {
    color: var(--color-primary);
}

.blockedMutedEmptyTxt {
  max-width: 320px;
}

.blockedMutedConttainer {
  border: solid 1px var(--color-light-grey);
  border-radius: 16px;
  &-row {
    padding: 22px 30px 30px 30px;
    border-bottom: solid 1px var(--color-light-grey);
  }
  &-last {
    border: none;
  }
  &-btn {
    color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
    border-radius: 8px;
    padding: 4px 15px;
    box-shadow: none !important;
  }
  &-slug {
    color: var(--color-light-grey-3);
  }
}