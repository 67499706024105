.riffSlider{
  padding: 0.1em 0.1em 0.5em 0.1em;

  
    // @media screen and (max-width: 1400px) {
    //   padding: 0.1em 0.1em 0.5em 0.1em;
    // }
  
    .play {
      width: 14px;
      height: 16px;
    }
  
    .comment {
      width: 17px;
      height: 16px;
    }
  
    .echo {
      width: 18px;
      height: 14px;
    }
  
    .like {
      width: 18px;
      height: 18px;
    }
  
    .like-inactive {
      width: 18px;
      height: 18px;
    }
  
    .options {
      width: 20px;
      height: 6px;
    }
  
    .count {
      padding-left: 5px;
      font-size: 14px;
      color: rgb(9, 8, 9);
    }
  
    .riff-footer {
      margin-top: 0.5em;
      align-items: center;
  
      .riff-footer-element{
        margin-right: 1.5em;
      }
    }
  
    .playFilled{
      width: 26px;
      height: 27px;
    }
  
    .riffButton {
      cursor: pointer;
    }
  
    .pauseFilled{
      width: 26px;
      height: 27px;
    }
  
    .audio-container{
      align-items: center;
    }
  
    .waveFormActive, .waveForm{
      width: 100%;
    }
  
    // .waveFormActiveTransition {
    //   clip-path: inset(0% 0% 0% 0%) !important;
    // }
  
    .riffName{
      margin-top: 0.5em;
    }
  
    .audioBar {
      width: 4px;
      border-radius: 4px;
      background-color: var(--color-primary);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
    }
    .audioBarSlider {
        border-radius: 5px;
        border: solid 4px var(--color-primary);
        position: absolute;
        top: 0;
        left: 0;
        bottom: -3px;
        cursor: grab;
    }
    .audioBar.horizTranslate {
      margin-left: 100% !important;
    }
  
    .audio-container-bar {
      position: relative;
      margin: auto;
    }
  }
  
  .riffSlider.hide{
    height: 0;
    opacity: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
  
  .riffSlider{
    transition: opacity 0.2s ease-in-out, height 0.2s, padding 0.5s, border 0.1s;
    height: 100%;
    overflow: hidden
  }
  
  .timeContainer {
    min-width: max-content;
  }
  