$home-horizontal-padding: 10em;
$home-horizontal-padding-tablet: 5em;
$home-horizontal-padding-mobile: 2em;

.homepage-footer {
  padding-left: $home-horizontal-padding;
  padding-right: $home-horizontal-padding;


  @media screen and (min-width: 961px) {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  @media screen and (max-width: 961px) {
    padding: 1em $home-horizontal-padding-tablet;
  }

  @media screen and (max-width: 767px) {
    padding: 0.7em 0;

    .nav-link{
      padding: 4px 1px;
    }

    .copyrights-container{
      padding: 0 20px;

      .nav-link {
        float: right;
      }
    }
    .nav-links-container{
      padding: 0 20px 10px 20px;
    }
  }

  .nav-link{
    color: black !important;
    font-family: var(--font-primary-bold), sans-serif;
    padding: 0.5rem 0.2rem;
  }
}
