.progress-bar-container > *:not(:last-child) {
  margin-right: 0.4em;
}

.progress-bar-item.active{
  background-color: rgb(83, 82, 254);
}

.progress-bar-item {
  background-color: rgba(83, 82, 254, 0.5);
  min-height: 0.55em;
  border-radius: 0.55em;
}