.trendingTitle {
    font-weight: bold;
    font-size: 25pt;
}

.seeMore {
    color: var(--color-light-grey-2);
}

.markedBlue {
    color: var(--color-secondary);
}

.hashTagTrending {
    border: solid 2px #e9ecef !important;
    border-radius: 10px;
    padding: 0 13px;
    font-size: 12pt;
    font-weight: bold;
    max-width: max-content !important;
    min-height: 50px;
    max-height: 50px;
}