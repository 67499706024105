.sortIcon {
    width: 20px;
    height: 17px;
}
  
.sortTxt {
    color: var(--color-black);
}

.sortIconContainer {
    width: 36px;
    @media (max-width: 992px) {
        width: 24px;
    }
}

.activeSort {
    color: var(--color-primary);
}

.sortItemRow {
    border-bottom: solid 2px var(--color-light-grey);
    padding: 12px 29px;
    &-last {
        padding: 12px 29px;
    }
}

.sortContainer {
    border: solid 2px var(--color-light-grey);
    border-radius: 15px;
    padding: 0;
}