.dropdown-toggle {
  background-color: transparent !important;
  padding: 0;
  border: none !important;
  box-shadow: none !important;
}

.dropdown-toggle::after {
  content: none;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: var(--color-primary);
}
