.form-control.app-text-input-password{
  border-right: none;
}

.app-text-area .form-control, .app-text-area .input-group-text {
  border-radius: 15px;
}

.app-text-area .form-control{
  border-right: none;
}

.numbers-of-characters-container {
  background-color: transparent;
}

.numbers-of-characters-container{
  align-items: flex-end;
  padding: 0 0.6em 0.3em 0.3em;
  border-left: none;
}

.numbers-of-characters{
  font-size: 0.8em;
  color: var(--color-primary);
}

.noCounter {
  border-right: solid 2px var(--color-light-grey) !important;
}

.noLabel {
  margin-top: 0.5rem;
}