.otp-container{
  font-family: var(--font-primary-bold), sans-serif;
  color: var(--color-black);
}

.otp-input {
  height: 2rem;
  font-size: 1.2rem;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid var(--color-light-grey);
  outline: transparent;
}

.otp-focus {
  border-top: none;
  border-left: none;
  border-right: none;
}

.otp-input-separator {
  width: 15px;
}
