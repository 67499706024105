.changePasswordBtn {
    padding: 0.9em 1.5em;
    color: var(--color-secondary) !important;
    border-color: var(--color-secondary) !important;
    border-radius: 10px;
    font-weight: bold;
    font-size: 20px;
    margin-top: 40px;

    @media (max-width: 991px) {
        padding: 0.5em 1em;
        margin-top: 30px;
    }
}