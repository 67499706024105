.clearTxt {
    color: var(--color-primary);
    font-size: 12pt;
}

.inputTxt {
    border-width: 0;
    background-color: #e9ecef !important;
    color: var(--color-black) !important;
    padding-top: 8px !important;
}

.iconImg {
    background-color: #e9ecef;
}

.searchIcon {
    border-width: 0 !important;
    width: 42px;
    height: 42px;
}