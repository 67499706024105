.draftContainer {
    border: solid 1px var(--color-light-grey);
    border-radius: 10px;
}

.draftRow {
    border-bottom: solid 1px var(--color-light-grey);
}

.confirmDelete {
    min-width: max-content;
    border: solid 3px var(--color-light-grey);
    padding: 10px 50px 10px 15px;
    border-radius: 15px;
    z-index: 5;
    background-color: var(--color-white);
    left: -60px;
    margin-top: 25px;
    color: var(--color-error);
    font-weight: bold;
    letter-spacing: 1px;
    box-shadow: 1px 2px var(--color-light-grey-2);
}


.gg-shape-triangle {
    position: absolute;
    transform: scale(var(--ggs,1));
    background-color: transparent;
    width: 22px;
    height: 17px;
    border-left: 3px solid transparent;
    border-bottom: 3px solid var(--color-white);
}
.gg-shape-triangle,
.gg-shape-triangle::before {
    display: block;
    box-sizing: border-box;
    border-right: 3px solid transparent;
    bottom: -28px;
    left: 20px;
    z-index: 6;
}
.gg-shape-triangle::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border-left: 3px solid var(--color-light-grey);
    border-top: 3px solid var(--color-light-grey);
    border-bottom: 3px solid transparent;
    transform: rotate(45deg) skew(10deg,10deg);
    left: -2px;
    bottom: -13px;
}

.dateTxt {
    letter-spacing: 1px;
}

.infiniteScroll {
    overflow: inherit !important;
}

.draftsIcon {
    width: 1.7rem;
    height: 1.7rem;
}

.draftsLoader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;
  }