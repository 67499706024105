@import "./assets/theme/scss-variables.scss";
@import "./assets/theme/css-variables.scss";
@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: SofiaPro-Regular;
  src: url('./assets/fonts/SofiaPro-Regular.otf');
  font-display: swap;
}

@font-face {
  font-family: SofiaPro-Bold;
  src: url('./assets/fonts/SofiaPro-Bold.otf');
  font-display: swap;
}

@font-face {
  font-family: SofiaPro-Light;
  src: url('./assets/fonts/SofiaPro-Light.otf');
  font-display: swap;
}

body {
  margin: 0;
  font-family: var(--font-primary), sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
