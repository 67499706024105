$home-horizontal-padding: 10em;
$home-horizontal-padding-tablet: 5em;
$home-horizontal-padding-mobile: 1em;

.logo-item {
  margin: auto;
}

.logo {
  width: 40px;
  height: 40px;

  @media screen and (max-width: 1400px) {
    width: 35px;
    height: 35px;
  }

  @media screen and (max-width: 967px) {
    width: 25px;
    height: 25px;
  }
}

.homepage-content {
  position: relative;
  top: 100px;

  @media (max-width: 1400px) {
    top: 4em;
  }
}

.homepage-first-section {
  background-color: var(--color-primary);
  color: var(--color-white);
  padding-top: 20px;

  @media (max-width: 1400px) {
    top: 65px;

    @media (min-width: 767px) {
      .app-sub-header{
        font-size: 1.2em;
      }

      .app-value{
        line-height: 2em;
        padding-top: 1em;
        padding-bottom: 1em;
      }
    }
  }

  .app-headline {
    line-height: 0.75em;
  }

  .riffr-title {
    @media (max-width: 961px) and (min-width: 767px) {
      padding-top: 30px;
    }
  }

  .stores-container{
    @media (max-width: 961px) and (min-width: 767px) {
      padding-bottom: 30px;
    }
  }
}

.homepage-first-section .left-container {
  padding-left: $home-horizontal-padding;
}

@media screen and (max-width: 961px) {
  .homepage-first-section .left-container {
    padding-left: $home-horizontal-padding-tablet;
  }
}

@media screen and (max-width: 767px) {
  .homepage-first-section .left-container {
    padding-left: 2em;
    text-align: center;
    padding-bottom: 1.5em;

    .app-value {
      line-height: 2em;
    }
  }
}

@media screen and (min-width: 961px) {
  .homepage-first-section .left-container {
    padding-top: 6em;
    padding-bottom: 6em;

    .app-value {
      line-height: 2em;
      padding-bottom: 1em;
    }
  }
}

@media screen and (max-width: 767px) {
  .homepage-first-section .right-container {
    display: none;
  }
}

.mockup {
  width: 720px;

  @media screen and (max-width: 1400px) {
    width: 550px;
  }

  @media screen and (max-width: 961px) {
    width: 350px;
  }
}

.downloadOnAppStore {
  height: 45pt;

  @media screen and (max-width: 1400px) {
    height: 30pt;
  }

  @media screen and (max-width: 767px) {
    float: right !important;
  }
}

.getItOnGooglePlay {
  height: 45pt;

  @media screen and (max-width: 1400px) {
    height: 30pt;
  }

  @media screen and (max-width: 767px) {
    float: left;
  }
}

.homepage-how-it-works-container {
  background-color: var(--color-secondary);
  color: var(--color-white);
  padding: 3em $home-horizontal-padding;

  .app-body {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  .how-it-works-body{
    line-height: 2.3em;
  }

  .how-it-works-content{
    padding-top: 1.5em;

    .how-it-works-body{
      padding-bottom: 2em;
    }
  }

  @media screen and (max-width: 961px) {
    padding: 2em $home-horizontal-padding-tablet 0 $home-horizontal-padding-tablet;
  }

  @media screen and (max-width: 767px) {
    padding: 2em $home-horizontal-padding-mobile 0 $home-horizontal-padding-mobile;
  }
}

.hear {
  width: 30px;
  height: 40px;
}

.share {
  width: 27px;
  height: 40px;
}

.community {
  width: 50px;
  height: 40px;
}

.hear, .community, .share{
  margin-bottom: 1em;
}

.homepage-join-container{
  padding: 3em $home-horizontal-padding 2.5em $home-horizontal-padding;
  background-image: url('../../assets/images/yellowIphone.png');
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 1200px) {
    background-image: url('../../assets/images/yellowIphone-tablet.png');
  }

  @media screen and (max-width: 961px) {
    padding: 2.45em $home-horizontal-padding-tablet;

    .app-value{
      font-family: var(--font-primary-light), sans-serif;
    }
  }

  @media screen and (max-width: 767px) {
    padding-left: $home-horizontal-padding-mobile;
    padding-right: $home-horizontal-padding-mobile;
    background-color: var(--color-yellow);
    background-image: url('../../assets/images/yellowIphone-mobile.png');
    background-repeat: no-repeat;
    background-size: contain;
    min-height:470px;
    background-position-y: bottom;
  }

  .app-body {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  .join-content{
    @media screen and (max-width: 1400px) {
      font-family: var(--font-primary-light), sans-serif;
    }
  }
}

.homepage-join-container{
  .app-value {
    margin-bottom: 1.5em;
    line-height: 1.75em;
  }

  .downloadOnAppStore{
    margin-right: 0.3em;
  }
}

.homepage-listen-container {
  padding: 5em $home-horizontal-padding 3em $home-horizontal-padding;

  @media screen and (max-width: 961px) {
    padding: 2em $home-horizontal-padding-tablet;
  }

  @media screen and (max-width: 767px) {
    padding: 2em $home-horizontal-padding-mobile;
  }

  .riff-element {
    padding-right: 10px;
    padding-bottom: 10px;
  }

  .app-value {
    padding-bottom: 2em;
    line-height: 2em;
  }
}

.social-micro {
  @media screen and (max-width: 1600px) and (min-width: 1400px) {
    font-size: 2em;
  }
}