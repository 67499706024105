.suspendingDef {
    font-size: 17px;
}

.suspendBtn {
    color: var(--color-error) !important;
    border-color: var(--color-error) !important;
    border-radius: 10px;
    padding: 0.5em 1.5em;
    font-weight: bold;
    font-size: 20px;
    margin-top: 40px;

    @media (max-width: 991px) {
        padding: 0.5em 1em;
        margin-top: 30px;
    }
}