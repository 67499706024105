.riffrLogoBlack {
  width: 50px;
}
.title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}
.message {
    text-align: center;
}
.popup {
    padding: 40px;
  .downloadOnAppStore {
    margin-right: 0.3em;
  }
  .col {
      padding: 10px 0 10px 0;
  }
}

.modal-content  {
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px; 
}
.storeImg {
  height: 26pt;
}

.riffrCom {
  border-bottom: 1px solid;
  color: var(--color-black) !important;
  font-family: var(--font-primary-light) !important;
}