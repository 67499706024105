.app-text-input {
  .eye-container {
    background-color: transparent;
  }

  .form-control{
    padding-top: 0.45em;
  }

  .form-control, .eye-container.active, .input-group-text {
    border-width: 2px;
  }

  .input-group-text.eye-container{
    padding-top: 0.275em;
    line-height: 27px;
    border-left: 0;
  }

  .error-message{
    padding-left: 0.75rem;
    padding-top: 0.4rem;
  }
}

.app-text-input.form-group{
  margin-bottom: 0.5rem;
}

.app-text-input-error{
  .form-control {
    border-color: var(--color-error) !important;
    border-width: 2px;
    box-shadow: none;
  }
  .eye-container {
    border-color: var(--color-error);
    border-width: 2px;
    border-left: none;
  }
}

.inputDisabledSlug {
  background-color: var(--color-white) !important;
  color: var(--color-secondary);
}