:root {
  --color-primary: #ff4c05;
  --color-primary-opacity: rgba(255, 91, 23, 0.5);
  --color-secondary: rgb(83, 82, 254);
  --color-secondary-medium-opacity: rgb(83, 82, 254, 0.5);
  --color-white: white;
  --color-white-opacity: rgba(255, 255, 255, 0.9);
  --color-yellow: #ffcf00;
  --color-light-grey: rgb(242, 242, 242);
  --color-light-grey-2: rgb(201, 201, 201);
  --color-light-grey-3: rgb(172, 172, 172);
  --color-black: #000000;
  --color-black-2: rgb(9, 8, 9);
  --color-error: rgb(244, 0, 0);
  --color-dark-mint: rgb(71, 190, 160);
  --font-primary: 'SofiaPro-Regular';
  --font-primary-bold: 'SofiaPro-Bold';
  --font-primary-light: 'SofiaPro-Light';
}
